import Parseable from '@/misc/Parseable';
import {PriceType} from "@/enum/PriceType.enum";
import {CancellationPeriod} from "@/enum/CancellationPeriod.enum";

export default class SubscriptionInfo extends Parseable {
    public cancellationDate!: string;
    public cancellationPeriod!: CancellationPeriod;
    public daysUntilCancellationPeriod!: string;
    public nextPaymentDate!: string;
    public subscriptionDate!: string;
    public priceType!: PriceType;
    public servicePrice!: number;

    public static parseFromObject(object: any): SubscriptionInfo {
        const service = new SubscriptionInfo();
        Object.assign(service, object);
        return service;
    }

    public parseToObject(): Partial<SubscriptionInfo> {
        return { ...this };
    }
}
