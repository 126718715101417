import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * deals with all the requests that have to do with accounts
 */
export default class MicroserviceRepository {
    /**
     * gets an account based on the token
     */
    public static getAll(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`api/microservice/${companyId}`);
    }

    public static getById(companyId: string, microserviceId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`api/microservice/${microserviceId}/${companyId}`);
    }

    public static subscribe(companyId: string, microServiceId: string) {
        return ENTITY_CLIENT.post(`api/microservice/${microServiceId}/${companyId}/subscribe`);
    }

    public static unsubscribe(companyId: string, microServiceId: string) {
        return ENTITY_CLIENT.put(`api/microservice/${microServiceId}/${companyId}/cancel`);
    }
}
