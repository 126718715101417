import Parseable from '@/misc/Parseable';
import {PriceType} from "@/enum/PriceType.enum";
import {CancellationPeriod} from "@/enum/CancellationPeriod.enum";
import {MicroServiceStatus} from "@/enum/MicroServiceStatus.enum";
import SubscriptionInfo from "@/models/SubscriptionInfo.model";
import {SubscriptionStatus} from "@/enum/SubscriptionStatus.enum";

export default class MicroService extends Parseable {
    public id!: string;
    public priceType!: PriceType;
    public cancellationInformation!: string;
    public cancellationPeriod!: CancellationPeriod;
    public paymentInformation!: string;
    public daysFree!: number;
    public daysUntilCancellationPeriod!: number;
    public title!: string;
    public description?: string;
    public status!: MicroServiceStatus;
    public servicePrice!: number;
    public subscriptionInfo!: SubscriptionInfo;
    public subscriptionStatus!: SubscriptionStatus;
    public subtitle!: string;
    public shortDescription!: string;


    public static parseFromObject(object: any): MicroService {
        const service = new MicroService();
        service.subscriptionInfo = SubscriptionInfo.parseFromObject(service.subscriptionInfo);
        Object.assign(service, object);
        return service;
    }

    public parseToObject(): Partial<MicroService> {
        return { ...this };
    }
}
